html, body {
  cursor: url('../../assets/cursor.png'), auto;
  
}

.Home {
    text-align: center;
  }
  
  .Home-logo {
    height: 70vmin;
    pointer-events: none;
    position: absolute;
    z-index: 0;
  }

  .Home-title {
    z-index: 1;
    margin-left: 4rem;
    position:absolute;
    bottom: 0;
    margin-bottom: 8rem;
    font-family: 'Bungee';
    
  }

  .Home-links {
    z-index: 1;
    
  }
  
  /* @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  } */
  
  .Home-header {
    background: url("../../assets/background.svg");
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 4vmin);
    color: white;
  }


  .button-link {
    background: white;
    box-shadow: 0px 0px 30px #fff;
    position: absolute;
    border: none; 
    border-radius: 50%;
    padding: 0;
    width: 30px; 
    height: 30px;
    background-size: cover;
    cursor: url('../../assets/pointer.png'), auto;
    animation: pulsate 2s ease-out infinite;

  }

  .button-link:hover {
    cursor: url('../../assets/pointer.png'), auto;

  }

  .link1 {
    cursor: url('../../assets/pointer.png'), auto;
    margin-right: 16.3rem; 
    margin-bottom: 8.5rem;

  }

  .link2 {
    cursor: url('../../assets/pointer.png'), auto;
    margin-left: 22rem; 
    margin-bottom: 9rem;

  }

  .link3 {
    cursor: url('../../assets/pointer.png'), auto;
    margin-right: 5rem; 
    margin-top: 18rem;
  }

  .glButton {
    background: transparent;
    border: none;
    cursor: pointer;
    width: 8vh;
    height: 5vh; 
    margin: 0 0 2.5rem 2rem;
  }

  .glButton:hover {
    cursor: url('../../assets/pointer.png'), auto;
  }




  @media screen and (max-width: 970px) {

    .glButton {
      width: 6vh;
      height: 4vh; 
      margin: 1rem 0 2.5rem 1rem;
    }

    .Home-title {
      margin-left: 2rem;
    }

    .Home-logo {
      height: 70vmin;
      pointer-events: none;
      position: absolute;
      z-index: 0;
    }
    
  }


  
  @media screen and (max-width: 470px) {


    .Home-logo {
      height: 100vmin;
      margin-right: 1rem;
    }

    .link1 {
      margin-left: 5rem;
      margin-top: 3rem;
    }

    .link2 {
      margin-left: 12.5rem;
      margin-bottom: 6rem;
    }

    .link3 {
      margin-right: 4rem; 
      margin-top: 11rem;
  
    }


    
  }

  @keyframes pulsate {
    0%   { box-shadow: 0 0 20px white; }
    50%  { box-shadow: 0 0 60px white; }
    100% { box-shadow: 0 0 20px white; }
}


  @font-face {
    font-family: 'Bungee';
    src: local('Bungee-Regular'), url(../../fonts/Bungee-Regular.woff) format('woff');
  }



  
  
  /* @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
  