
.galactic-main {
    height: 100vh;
    color: white;
    font-family: Bungee;
}

.homeButton {
    margin: 5rem 0; 
    margin-left: 110rem;
}

.headerOne {
    font-size: 64px;
    padding-top: 4rem;
}

.headerTwo {
    font-size: 48px;
    margin: 1rem 0;
}

.galactic-container-one {
    /* background: url('../../assets/backgroundani.gif'); */
    background-repeat: no-repeat; 
    background-size: cover;
    margin-top: 7rem;
    
}



.galactic-mission {
    color: white;
    font-family: Bungee;
    padding: 1rem 4rem;
    text-align: left;
    background-color: gray;
    border-radius: 10px;
    margin: 6rem 20rem 0 20rem;
    box-shadow: 0 3px 60px rgba(255, 255, 255, 0.2);
}


.galactic-mission h1 {
    text-align: center;
}

.galactic-body {
    color: white;
    font-family: Bungee;
    text-align: left;
    margin-top: 6rem;
}

.galactic-container-two {
    padding: 0;
    margin: 5rem;
    justify-content: center;
    display: flex;
    flex-wrap: nowrap;
}

.galactic-container-two-mobile {
    display: none;
}

.galactic-container-two img {
    transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
    
}

.partners {
    width: 100%;
    background: black;
    padding: 1rem 0;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    
}


.partners-ribbon {
    width: 100%;
    background: black;
    padding: 0.5rem;
    
}

.partners-ribbon img {
    margin: 0 10rem;
}

.partners img {
    max-width: 100%;
    max-height: 100%;
    margin-right: 9rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

.slider {
    margin: 8rem 0 5rem 0;
    box-shadow: 0 3px 60px rgba(255, 255, 255, 0.2);
  }

.mobileCarousel {
    display: none;
}

.carousel {
    margin: 4rem 0;
}

.glow {
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }
  
  
  @keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #817b7e, 0 0 40px #796f74, 0 0 50px #918a8d, 0 0 60px #bba0ae, 0 0 70px #3b393a;
    }
    to {
      text-shadow: 0 0 20px rgb(238, 236, 236), 0 0 30px #f0eaed, 0 0 40px #615d5f, 0 0 50px #7c797b, 0 0 60px #807f7f, 0 0 70px #a7a2a4, 0 0 80px #aa9aa2;
    }
  }



  
  @media screen and (max-width: 470px) {

    .mobileCarousel {
        display: contents;
    }

    .carousel {
        display: none;
    }

    .homeButton {
        margin-left: 18rem;
    }

    .headerOne {
        font-size: 48px;
        padding-top: 6rem;
    }
    
    .headerTwo {
        font-size: 36px;
        margin: 3rem 0;
    }

    .galactic-mission {
        padding: 2rem;
        margin-top: 2rem;
    }

    .partners {
        width: 100%;
        
    }

    .galactic-main {
        height: 100%;
    }

    .galactic-body {

        padding: 2rem;

    }

    .galactic-container-two  {
        display: none;
    }

    .galactic-container-two-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
    }


  }


  @keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}







