.homeButton {
    margin: 5rem 0; 
    margin-left: 110rem;
}

.text-style {
    color: white;
    font-family: Bungee;
}

#video {
    z-index: -1;
    width: 100%;

}

.beoBody {
    background: url(../../assets/bg-teaser.gif);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}

.navbarBEO {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    padding: 1.5rem 0 0 7rem;
}

ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    font-family: Teko,sans-serif;
}

li {
    padding: 0 2rem;
}

.left-container, .right-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 4rem;
}
